import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
	{ // 登录
		path: '/signin',
		name: 'Signin',
		component: () => import("../appviews/Signin.vue")
	},
	/* 供应商端 */
	{ // 供应商注册
		path: '/registry',
		name: 'Registry',
		component: () => import("../appviews/supplier/Registry.vue")
	},
	{ // 供应商缴费
		path: '/pay',
		name: 'Pay',
		component: () => import("../appviews/supplier/Pay.vue")
	},
	{ // 应用主页(子页面统一增加s-开头用于区分所属端)
		path: '/s-index',
		name: 'SupplierIndex',
		component: () => import("../appviews/supplier/Index.vue"),
		children: [
			{ // 首页
				path: '/s-home',
				name: 'SupplierHome',
				component: () => import("../appviews/supplier/Home.vue")
			},
			{ // 通知待办
				path: '/s-notice',
				name: 'SupplierNotoce',
				component: () => import("../appviews/common/Notice.vue")
			},
			{ // 通知详情
				path: '/s-notice-detail',
				name: 'SupplierNotoceDetail',
				component: () => import("../appviews/common/NoticeDetail.vue")
			},
			{ // 账号管理
				path: '/s-account',
				name: 'SupplierAccount',
				component: () => import("../appviews/common/Account.vue")
			},
			{ // 项目列表
				path: '/s-project',
				name: 'SupplierProject',
				component: () => import("../appviews/supplier/Inquiry.vue")
			},
			{ // 询价单
				path: '/s-inquiry',
				name: 'SupplierInquiry',
				component: () => import("../appviews/supplier/Inquiry.vue")
			},
			{ // 报价单
				path: '/s-offer',
				name: 'SupplierOffer',
				component: () => import("../appviews/supplier/Offer.vue")
			},
			{ // 缴费记录
				path: '/s-paylist',
				name: 'SupplierPaylist',
				component: () => import("../appviews/supplier/Paylist.vue")
			},
		]
	},
	/* 采购(管理)端 */
	{ // 应用主页(子页面统一增加p-开头用于区分所属端)
		path: '/p-index',
		name: 'PurchaserIndex',
		component: () => import("../appviews/purchaser/Index.vue"),
		children: [
			{ // 首页
				path: '/p-home',
				name: 'PurchaserHome',
				component: () => import("../appviews/purchaser/Home.vue")
			},
			{ // 采购项目管理
				path: '/p-project',
				name: 'PurchaserProject',
				component: () => import("../appviews/purchaser/Project.vue")
			},
			{ // 新建采购项目
				path: '/p-project-new',
				name: 'PurchaserProjectNew',
				component: () => import("../appviews/purchaser/ProjectNew.vue")
			},
			{ // 采购计划管理
				path: '/p-plan',
				name: 'PurchaserPlan',
				component: () => import("../appviews/purchaser/Plan.vue")
			},
			{ // 新建采购计划
				path: '/p-plan-new',
				name: 'PurchaserPlanNew',
				component: () => import("../appviews/purchaser/PlanNew.vue")
			},
			{ // 新建询价单
				path: '/p-inquiry-new',
				name: 'PurchaserInquiryNew',
				component: () => import("../appviews/purchaser/InquiryNew.vue")
			},
			{ // 询价单管理
				path: '/p-inquiry',
				name: 'PurchaserInquiry',
				component: () => import("../appviews/purchaser/Inquiry.vue")
			},
			{ // 项目经理认价
				path: '/p-preselect',
				name: 'PurchaserPreselect',
				component: () => import("../appviews/purchaser/Preselect.vue")
			},
			{ // 组织专家评审
				path: '/p-specialist-check',
				name: 'PurchaserSpecialistCheck',
				component: () => import("../appviews/purchaser/SpecialistCheck.vue")
			},
			{ // 统计汇总
				path: '/p-statistical',
				name: 'PurchaserStatistical',
				component: () => import("../appviews/purchaser/Statistical.vue")
			},
			{ // 通知待办
				path: '/p-notice',
				name: 'PurchaserNotoce',
				component: () => import("../appviews/common/Notice.vue")
			},
			{ // 账号管理
				path: '/p-account',
				name: 'PurchaserAccount',
				component: () => import("../appviews/purchaser/Account.vue")
			},
			{ // 账号管理
				path: '/p-account-new',
				name: 'PurchaserAccountNew',
				component: () => import("../appviews/purchaser/AccountNew.vue")
			},
			{ // 专家管理
				path: '/p-specialist',
				name: 'PurchaserSpecialist',
				component: () => import("../appviews/purchaser/Specialist.vue")
			},
			{ // 专家商详情
				path: '/p-specialist-detail',
				name: 'PurchaserSpecialistDetail',
				component: () => import("../appviews/purchaser/SpecialistDetail.vue")
			},
			{ // 供应商管理
				path: '/p-supplier',
				name: 'PurchaserSupplier',
				component: () => import("../appviews/purchaser/Supplier.vue")
			},
			{ // 供应商详情
				path: '/p-supplier-detail',
				name: 'PurchaserSupplierDetail',
				component: () => import("../appviews/purchaser/SupplierDetail.vue")
			},
			{ // 供应商详情
				path: '/p-supplier-pay',
				name: 'PurchaserSupplierPay',
				component: () => import("../appviews/purchaser/SupplierPay.vue")
			},
			{ // 缴费管理
				path: '/p-paylist',
				name: 'PurchaserPaylist',
				component: () => import("../appviews/purchaser/Paylist.vue")
			},
			{ // 组织机构
				path: '/p-organization',
				name: 'PurchaserOrganization',
				component: () => import("../appviews/purchaser/Organization.vue")
			},
		]
	},
	/* 管理后台 */
	{ // 主页
		path: '/',
		name: 'Index',
		component: () => import("../views/Index.vue"),
		children: [
			// 框架组成模块
			{ // 首页
				path: '/home',
				name: 'Home',
				component: () => import("../views/modules/Home.vue")
			},
			{ // 消息管理
				path: '/message',
				name: 'Message',
				component: () => import("../views/modules/Message.vue")
			},
			{ // 账号管理
				path: '/account',
				name: 'Account',
				component: () => import("../views/modules/Account.vue")
			},
			{ // 密码管理
				path: '/passedit',
				name: 'Passedit',
				component: () => import("../views/modules/Passedit.vue")
			},
			
			// 系统设置模块
			{ // 菜单模块管理
				path: '/menu',
				name: 'Menu',
				component: () => import("../views/sys/Menu.vue")
			},
			{ // 角色权限管理
				path: '/role',
				name: 'Role',
				component: () => import("../views/sys/Role.vue")
			},
			{ // 平台用户管理
				path: '/user',
				name: 'User',
				component: () => import("../views/sys/User.vue")
			},
			{ // 参数配置管理
				path: '/config',
				name: 'Config',
				component: () => import("../views/sys/Config.vue")
			},
			{ // 操作日志查询
				path: '/logs',
				name: 'Logs',
				component: () => import("../views/sys/Logs.vue")
			},
		]
	}
]

export default createRouter({
	history: createWebHashHistory(),
	routes
})